import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";
import { page } from "components/page";
import Login from "components/login/Login";

@withI18next(["common"])
@page
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Layout {...this.props} isLoginPage={true} hiddenBg={true}>
        <div className="main login_page" id="center">
          <a class="accesskey" href="#aC" id="aC" accesskey="C" title="主要內容區">:::</a>
          <section>
            <div className="container">
              <Login
                isPage={true}
                appStore={this.props.appStore}
              />
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default LoginPage;
